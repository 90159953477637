import React from "react";

import TermService from "domains/TermService";
import TermsView from "domains/TermsView";
import SEO from "components/seo";

export default function TermServicePage(props) {
  return (
    <>
      <SEO
        title="Beyond Innovation, Driving Impact"
        description="Qlue provides comprehensive smart city solutions focusing on Artificial Intelligence (AI), Internet of Things (IoT), and mobile workforce technology"
      />
      <TermsView location={props.location} keyName="termService" uri={props.uri}>
        <TermService {...props} />
      </TermsView>
    </>
  );
}
