// dependency libraries
import React from 'react'

// root modules
import { FormattedMessage } from 'utils/intl'

// sibling modules
import { Title, Desc } from './styles'

function TermServiceDomain() {
  return (
    <>
      <Title><FormattedMessage id="terms-of-service" /></Title>
      <Desc>
        <FormattedMessage id="thanks-for-using" /> (
        <strong>&quot;<FormattedMessage id="terms-2" />&quot;</strong>, <strong>&quot;<FormattedMessage id="terms-of-service" />&quot;</strong>) <FormattedMessage id="describe-your" />{ ' ' }
        <strong>&quot;<FormattedMessage id="service" />&quot;</strong>) <FormattedMessage id="created-by" /> (<strong>&quot;<FormattedMessage id="us" />&quot;</strong>,{ ' ' }
        <strong>&quot;<FormattedMessage id="we" />&quot;</strong>, <FormattedMessage id="or" /> <strong>&quot;<FormattedMessage id="our" />&quot;</strong>).{ ' ' }
        <strong>&quot;<FormattedMessage id="you" />&quot;</strong> <FormattedMessage id="means-the-entity" />
        <br />
        <br />
        <FormattedMessage id="if-you-are" />
        <ol style={{ listStyleType: 'lower-roman' }}>
          <li>
            <FormattedMessage id="you-have-full" />
          </li>
          <li><FormattedMessage id="you-have-read" /></li>
          <li><FormattedMessage id="you-agree-to" /></li>
        </ol>
        <FormattedMessage id="if-you-dont" />
        <br />
        <br />
        <strong>
          <FormattedMessage id="please-note-that" />
          <br />
          <ol style={{ listStyleType: 'upper-alpha' }}>
            <li><FormattedMessage id="you-will-be" /></li>
            <li><FormattedMessage id="your-click-to" /></li>
            <li>
              <FormattedMessage id="the-word-you" />
            </li>
          </ol>
          <i>
            <FormattedMessage id="these-terms-are" />
          </i>
        </strong>
        <br />
        <br />
        <FormattedMessage id="if-you-have" />{ ' ' }
        <a href="mailto:info@qlue.id" target="_blank" rel="noreferrer">
          info@qlue.id
        </a>
      </Desc>
    </>
  )
}

export default TermServiceDomain
