import styled from "styled-components";

import { darkBlueGrey } from "components/baseColor";

export const Title = styled.div`
  font-size: ${({ fontSize }) => fontSize || "20px"};
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  text-decoration: none;

  @media (max-width: 414px) {
    font-size: 15px;
    margin: 20px;
  }
`;

export const Desc = styled.p`
  font-size: 1.17em;
  line-height: 1.56;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  margin: 15px 0;

  ol {
    list-style-type: none;
  }

  @media (max-width: 414px) {
    font-size: 1em;
    margin: 20px;
  }
`;
